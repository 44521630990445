import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Orders.module.scss'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select';
import { colourStylesStatus } from '../../components/SelectStatusStyles';
import { colourStylesCurrency } from '../../components/SelectCurrencyStyles';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import AccountsTable from '../../components/AccountsTable';
import OrdersTable from '../../components/OrdersTable';
import moment from 'moment';
import altIcon from '../../imgs/altIcon.svg'
import MobileOrderDrawer from '../../components/MobileOrderDrawer';
export default function Orders({ theme, eurToUsd ,setSnackType,setSnackMessage,handleClick,account}) {
    const { t, i18n } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('open');
    const [backgroundTableStyle, setBackgroundTableStyle] = useState({});
    const open = useRef(null);
    const deferred = useRef(null);
    const close = useRef(null);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [currentRecordsB, setCurrentRecordsB] = useState([]);
    const [history, setHistory] = useState([]);
    const [historyB, setHistoryB] = useState([]);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [activeOrder , setActiveOrder] = useState({})
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState(false);
    const [searchCurrency, setSearchCurrency] = useState(false);
  const toggleDrawer = (newOpen,element) => () => {
    setOpenDrawer(newOpen);
    setActiveOrder(element)
  };
    const handleImgError = (e) => {
        e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
    };
    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }

    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );

    const handleTabTableChange = (tab) => {
        setSelectedTab(tab)
    };

    const updateBackgroundPositionTable = () => {
        let targetTab;
        switch (selectedTab) {
            case 'open':
                targetTab = open.current;
                break;
            case 'deferred':
                targetTab = deferred.current;
                break;
            case 'close':
                targetTab = close.current;
                break;
            default:
                targetTab = open.current;
        }

        if (targetTab) {
            setBackgroundTableStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    useEffect(() => {
        updateBackgroundPositionTable();
    }, [selectedTab]);

    useEffect(() => {
        var timer;
        const fetchData = async () => {

            await axios.get(process.env.REACT_APP_test_url + `/api/assets/user/${secureLocalStorage.getItem('user')?.Id}`, {
                headers: {
                    'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                }
            })
                .then((res) => {
                    setCurrentRecords(res.data);
                    // setCurrentRecordsB(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
            timer = setInterval(() => {
                axios.get(process.env.REACT_APP_test_url + `/api/assets/user/${secureLocalStorage.getItem('user')?.Id}/history`, {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                }).then((result) => {
                    setHistory(result.data.filter(el => el.Status == 'CLOSED' || el.Status == 'KILLED'))
                    // setHistoryB(result.data.filter(el => el.Status == 'CLOSED' || el.Status == 'KILLED'))
                }).catch(e => console.log(e))
                axios.get(process.env.REACT_APP_test_url + `/api/assets/user/${secureLocalStorage.getItem('user')?.Id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        setCurrentRecords(res.data);
                        // setCurrentRecordsB(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }, 2000)
        }
        fetchData()

        return ()=>{
            clearInterval(timer);
        }
    }, [])

    const filterAccounts = () => {
        if (search?.length || searchType || searchCurrency) {
          return currentRecords.filter((val) => {
            const matchesSearch = search?.length
              ? (val?.Title?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()) || val?.AccountId?.toString().includes(search) || val?.Id?.toString().includes(search) || val?.Subtitle?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()))  
              : true;
      
            const matchesType = searchType
              ? val?.Status.toLowerCase().includes(searchType?.value === 1 ? "buy" : "sell")
              : true;
      
           
      
            return matchesSearch && matchesType ;
          });
        } else {
          return currentRecords;
        }
    };
    const filterH = () => {
        if (search?.length || searchType) {
          return history.filter((val) => {
            const matchesSearch = search?.length
              ? (val?.Asset?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()) || val?.AccountId?.toString().includes(search) || val?.Id?.toString().includes(search) || val?.SubTitle?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()))
              : true;
      
            const matchesType = searchType
              ? val?.OrderType?.toLowerCase().includes(searchType?.value === 1 ? "buy" : "sell")
              : true;
      
           
      
            return matchesSearch && matchesType ;
          });
        } else {
          return history;
        }
    };
    useEffect(()=>{
        setCurrentRecordsB(filterAccounts())
        setHistoryB(filterH())
    },[search,searchType,currentRecords , history])

    const getLiquidationPrice = (row) => {
        const user = secureLocalStorage.getItem("user");
        const currency = user?.Currency || "USD"; // Предполагаем, что по умолчанию USD
        const currencyFactor = currency === "EUR" ? eurToUsd : 1;
        const symbol = currency === "EUR" ? "€" : "$";
        
        const startPrice = parseFloat(row.original.StartPrice) * currencyFactor;
        const leverage = parseFloat(row.original.Leverage);
    
        // Если кредитное плечо равно 1, ликвидационная цена не применяется
        if (leverage === 1) {
            return '-- --';
        }
    
        let liquidationPrice;
    
        if (row.original.Status === 'BUY') {
            // Формула для длинной позиции (BUY)
            liquidationPrice = startPrice * (1 - 1 / leverage);
        } else {
            // Формула для короткой позиции (SELL)
            liquidationPrice = startPrice * (1 + 1 / leverage);
        }
    
        // Форматируем результат до 4 знаков после запятой
        return `${liquidationPrice.toFixed(4)} ${symbol}`;
    }
    

    const columns = useMemo(
        () => [
            {
                accessorKey: 'Id',
                header: t('OrderId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('OrderId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'AccountId',
                header: t('AccountId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('AccountId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'Title',
                header: t('Instrument'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Instrument')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ cursor: 'pointer' }}>
                            {row.original.Title} {row.original.Subtitle}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Status',
                header: t('Type'),
                size: 90,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Type')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={row.original.Status === 'BUY' ? { color: '#00B4A0', backgroundColor: 'rgba(0, 180, 160, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : row.original.Status === 'SELL' ? { color: '#F63D68', backgroundColor: 'rgba(246, 61, 104, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : {}}>
                            {row.original.Status.slice(0, 1) + row.original.Status.slice(1).toLowerCase()}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Price',
                header: t('Margin'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Margin')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {parseFloat(((row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)) * row.original.Count * row.original.LotSize) / row.original.Leverage).toFixed(2)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'StartPrice',
                header: t('EntryPrice'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('EntryPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(2)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Liquidation',
                header: t('Liquidation'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Liquidation')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={row.original.Leverage !== 1 ? { color: '#F63D68' } : { color: '#9F9C9C' }}>
                            {
                                getLiquidationPrice(row)
                            }
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Leverage',
                header: t('Leverage'),
                size: 80,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Leverage')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ backgroundColor: 'rgba(233, 119, 14, 0.08)', color: '#E9770E', padding: '0.4vh 1.2vh', borderRadius: '100px' }}>
                            X{row.original.Leverage}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Market',
                header: t('MarketPrice'),
                size: 80,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('MarketPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(parseFloat(row.original.MarketPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(4)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Profit',
                header: t('Profit'),
                size: 80,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Profit')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                row.original.Status === 'BUY' ?
                                    <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
                                        {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
                                        {
                                            account?.AccountCurrency == "EUR"
                                                ?
                                                "€"
                                                :
                                                "$"
                                        }
                                        {parseFloat(row.original.Profit) > 0 ? parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1)
                                            : parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1).slice(1)}
                                    </div>
                                    :
                                    <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
                                        {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
                                        {
                                            account?.AccountCurrency == "EUR"
                                                ?
                                                "€"
                                                :
                                                "$"
                                        }
                                        {parseFloat(row.original.Profit) > 0 ? parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1) : parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1).slice(1)}
                                    </div>
                            }
                        </span>
                    )
                },
            },
            {
                accessorKey: 'TakeStop',
                header: t('TP&SL'),
                size: 80,
                enableSorting: false,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('TP&SL')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <span style={{ fontSize: '12px', color: '#3F3F46' }}>
                                {row.original.TakeProfit ? <span style={{ color: '#00B4A0' }}>{(row.original.TakeProfit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed()}</span> : <span style={{ color: '#00B4A0' }}>0                                         {
                                    account?.AccountCurrency == "EUR"
                                        ?
                                        "€"
                                        :
                                        "$"
                                }</span>} / {row.original.StopLoss ? <span style={{ color: '#F63D68' }}>{(row.original.StopLoss * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed()}</span> : <span style={{ color: '#F63D68' }}>0                                         {
                                    account?.AccountCurrency == "EUR"
                                        ?
                                        "€"
                                        :
                                        "$"
                                }</span>}
                            </span>
                        </span>
                    )
                },
            },
        ],
        [i18n.language, account?.AccountCurrency, currentRecords],
    )

    const columnsDeferred = useMemo(
        () => [
            {
                accessorKey: 'Id',
                header: t('OrderId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('OrderId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'AccountId',
                header: t('AccountId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('AccountId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'Title',
                header: t('Instrument'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Instrument')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ cursor: 'pointer' }}>
                            {row.original.Title} {row.original.Subtitle}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Status',
                header: t('Type'),
                size: 80,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Type')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={row.original.Status === 'BUYD' ? { color: '#00B4A0', backgroundColor: 'rgba(0, 180, 160, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : row.original.Status === 'SELLD' ? { color: '#F63D68', backgroundColor: 'rgba(246, 61, 104, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : { backgroundColor: 'rgba(233, 119, 14, 0.08)', color: '#E9770E', padding: '0.4vh 1.2vh', borderRadius: '100px' }}>
                            {row.original.Status.slice(0, 1) + row.original.Status.slice(1).toLowerCase()}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Price',
                header: t('Margin'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Margin')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {parseFloat(((row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)) * row.original.Count * row.original.LotSize) / row.original.Leverage).toFixed(2)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'StartPrice',
                header: t('EntryPrice'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('EntryPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(2)}
                        </span>
                    )
                },
            },
            // {
            //     accessorKey: 'Liquidation',
            //     header: t('Liquidation'),
            //     size: 110,
            //     Header: () => (
            //         <div className={styles.header_cell}>
            //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Liquidation')}</span>
            //             <div className={styles.sort_icon}>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //             </div>
            //         </div>
            //     ),
            //     Cell: ({ row }) => {
            //         return (
            //             <span style={row.original.Leverage !== 1 ? { color: '#F63D68' } : { color: '#9F9C9C' }}>
            //                 {
            //                     row.original.Status === 'BUY'
            //                         ?
            //                         row.original.Leverage !== 1
            //                             ?
            //                             `${(+(row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)) - ((+row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(4) * (100 / (+row.original.Leverage * 100)))).toFixed(4)} ${account?.AccountCurrency == "EUR"
            //                                 ?
            //                                 "€"
            //                                 :
            //                                 "$"}`
            //                             : '-- --'
            //                         : row.original.Leverage !== 1 ?
            //                             `${(+(row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)) + ((+row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(4) * (100 / (+row.original.Leverage * 100)))).toFixed(4)}
  
            //                       ${account?.AccountCurrency == "EUR"
            //                                 ?
            //                                 "€"
            //                                 :
            //                                 "$"}`
            //                             : '-- --'
            //                 }
            //             </span>
            //         )
            //     },
            // },
            {
                accessorKey: 'Leverage',
                header: t('Leverage'),
                size: 70,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Leverage')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ backgroundColor: 'rgba(233, 119, 14, 0.08)', color: '#E9770E', padding: '0.4vh 1.2vh', borderRadius: '100px' }}>
                            X{row.original.Leverage}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Market',
                header: t('MarketPrice'),
                size: 80,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('MarketPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(parseFloat(row.original.MarketPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(4)}
                        </span>
                    )
                },
            },
            // {
            //     accessorKey: 'Profit',
            //     header: t('Profit'),
            //     size: 80,
            //     Header: () => (
            //         <div className={styles.header_cell}>
            //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Profit')}</span>
            //             <div className={styles.sort_icon}>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //             </div>
            //         </div>
            //     ),
            //     Cell: ({ row }) => {
            //         return (
            //             <span>
            //                 {
            //                     row.original.Status === 'BUY' ?
            //                         <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
            //                             {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
            //                             {
            //                                 account?.AccountCurrency == "EUR"
            //                                     ?
            //                                     "€"
            //                                     :
            //                                     "$"
            //                             }
            //                             {parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1)}
            //                         </div>
            //                         :
            //                         <div style={parseFloat(row.original.Profit) > 0 ? { color: '#00B4A0' } : { color: '#F63D68' }}>
            //                             {parseFloat(row.original.Profit) > 0 ? '+ ' : '- '}
            //                             {
            //                                 account?.AccountCurrency == "EUR"
            //                                     ?
            //                                     "€"
            //                                     :
            //                                     "$"
            //                             }
            //                             {parseFloat((row.original.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))).toFixed(1).slice(1)}
            //                         </div>
            //                 }
            //             </span>
            //         )
            //     },
            // },
            {
                accessorKey: 'TakeStop',
                header: t('TP&SL'),
                size: 120,
                enableSorting: false,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('TP&SL')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <span style={{ fontSize: '12px', color: '#3F3F46' }}>
                                {row.original.TakeProfit ? <span style={{ color: '#00B4A0' }}>{(row.original.TakeProfit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed()}</span> : <span style={{ color: '#00B4A0' }}>0                                         {
                                    account?.AccountCurrency == "EUR"
                                        ?
                                        "€"
                                        :
                                        "$"
                                }</span>} / {row.original.StopLoss ? <span style={{ color: '#F63D68' }}>{(row.original.StopLoss * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed()}</span> : <span style={{ color: '#F63D68' }}>0                                         {
                                    account?.AccountCurrency == "EUR"
                                        ?
                                        "€"
                                        :
                                        "$"
                                }</span>}
                            </span>
                        </span>
                    )
                },
            },
        ],
        [i18n.language, account?.AccountCurrency, currentRecords],
    )
    const columnsClosed = useMemo(
        () => [
            {
                accessorKey: 'Id',
                header: t('OrderId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('OrderId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'AccountId',
                header: t('AccountId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('AccountId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
            },
            {
                accessorKey: 'Asset',
                header: t('Instrument'),
                size: 140,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Instrument')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {row.original.Asset} {row.original.SubTitle}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'OrderType',
                header: t('Type'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Type')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 60,
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span style={row.original.OrderType?.includes('BUY') ? { color: '#00B4A0', backgroundColor: 'rgba(0, 180, 160, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : row.original.OrderType?.includes('SELL') ? { color: '#F63D68', backgroundColor: 'rgba(246, 61, 104, 0.08)', padding: '0.4vh 1.2vh', borderRadius: '100px' } : {}}>
                            {row.original.OrderType?.includes('D') ? row.original.OrderType?.slice(0, -1) : row.original?.OrderType?.slice(0, 1) + row.original.OrderType?.slice(1).toLowerCase()}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Price',
                header: t('Amount'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Amount')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.Price * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'StartPrice',
                header: t('EntryPrice'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('EntryPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.StartPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'EndPrice',
                header: t('EndPrice'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('EndPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.EndPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'EndPrice',
                header: t('EndPrice'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('EndPrice')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.EndPrice * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Leverage',
                header: t('Leverage'),
                size: 70,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Leverage')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span style={{ backgroundColor: 'rgba(233, 119, 14, 0.08)', color: '#E9770E', padding: '0.4vh 1.2vh', borderRadius: '100px' }}>
                            X{row.original.Leverage}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Status',
                header: t('Status'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Status')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span style={row.original.Status === 'CLOSED' ? { backgroundColor: 'rgba(233, 119, 14, 0.08)', color: '#E9770E', fontSize: '1.2vh', padding: '0.4vh 1.2vh', borderRadius: '100px' } : row.original.Status === 'KILLED' ? { color: '#F63D68', padding: '4px 8px', fontSize: '1.2vh', backgroundColor: 'rgba(246, 61, 104, 0.08)', borderRadius: '100px' } : {}}>
                            {row.original.Status?.slice(0, 1) + row.original.Status?.slice(1).toLowerCase()}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Date',
                header: t('Date'),
                size: 140,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Date')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {moment(row.original.Date).format('YYYY-MM-DD')}
                            <span style={{ color: theme === 'dark' ? "#51525C" : "#9CA3AF" }}> {moment(row.original.Date).format('HH:mm')}</span>
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Profit',
                header: t('Profit'),
                size: 90,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Profit')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span style={row.original?.Profit < 0 ? { color: '#F63D68', fontWeight: '500' } : { color: '#00B4A0', fontWeight: '500' }}>

                            {
                                row.original.Status === 'CLOSED' ?
                                    `${(row.original?.Profit * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(2)} ${account?.AccountCurrency == "EUR" ? "€" : "$"}`
                                    :
                                    <span style={{ color: '#F63D68' }}>
                                        {'-' + (row.original?.Price * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(2)}  {account?.AccountCurrency == "EUR" ? "€" : "$"}
                                    </span>}
                        </span>
                    )
                },
            },
        ],
        [i18n.language, account?.AccountCurrency, currentRecords],
    );
    
    return (
        <div className={styles.orders}>
            <div className={styles.orders_title}>
                <svg xmlns="http://www.w3.org/2000/svg" width={"1.6vh"} height={"1.6vh"} viewBox="0 0 16 16" fill="none">
                    <path d="M6.33333 1.33398C5.78105 1.33398 5.33333 1.7817 5.33333 2.33398V3.00065C5.33333 3.55294 5.78105 4.00065 6.33333 4.00065H9.66667C10.219 4.00065 10.6667 3.55294 10.6667 3.00065V2.33398C10.6667 1.7817 10.219 1.33398 9.66667 1.33398H6.33333Z" fill="#FCFCFC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.33333 2.69173C3.49473 2.73878 2.96532 2.87241 2.58579 3.25195C2 3.83773 2 4.78054 2 6.66616V10.6662C2 12.5518 2 13.4946 2.58579 14.0804C3.17157 14.6662 4.11438 14.6662 6 14.6662H10C11.8856 14.6662 12.8284 14.6662 13.4142 14.0804C14 13.4946 14 12.5518 14 10.6662V6.66616C14 4.78054 14 3.83773 13.4142 3.25195C13.0347 2.87241 12.5053 2.73878 11.6667 2.69173V3.00065C11.6667 4.10522 10.7712 5.00065 9.66667 5.00065H6.33333C5.22876 5.00065 4.33333 4.10522 4.33333 3.00065V2.69173ZM4.66667 9.16732C4.39052 9.16732 4.16667 9.39118 4.16667 9.66732C4.16667 9.94346 4.39052 10.1673 4.66667 10.1673H10C10.2761 10.1673 10.5 9.94346 10.5 9.66732C10.5 9.39118 10.2761 9.16732 10 9.16732H4.66667ZM4.66667 11.5007C4.39052 11.5007 4.16667 11.7245 4.16667 12.0007C4.16667 12.2768 4.39052 12.5007 4.66667 12.5007H8.33333C8.60948 12.5007 8.83333 12.2768 8.83333 12.0007C8.83333 11.7245 8.60948 11.5007 8.33333 11.5007H4.66667Z" fill="#FCFCFC" />
                </svg>
                {t('Orders')}
            </div>
            <div className={styles.orders_table}>
                <div className={styles.table_header}>
                    <div className={styles.tabs}>
                        <div className={styles.background} style={backgroundTableStyle} />
                        <button
                            ref={open}
                            className={`${styles.tab} ${selectedTab === 'open' ? styles.active : ''}`}
                            onClick={() => handleTabTableChange('open')}
                        >
                            {t("Open Orders")}
                        </button>
                        <button
                            ref={deferred}
                            className={`${styles.tab} ${selectedTab === 'deferred' ? styles.active : ''}`}
                            onClick={() => handleTabTableChange('deferred')}
                        >
                            {t("Deferred Orders")}
                        </button>
                        <button
                            ref={close}
                            className={`${styles.tab} ${selectedTab === 'close' ? styles.active : ''}`}
                            onClick={() => handleTabTableChange('close')}
                        >
                            {t("History")}
                        </button>
                    </div>
                    <div className={styles.right_side}>
                        <div className={styles.search}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_339_5008)">
                                    <circle cx="7.66659" cy="7.66634" r="6.33333" stroke="#51525C" />
                                    <path d="M13.3333 13.333L14.6666 14.6663" stroke="#51525C" strokeLinecap="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_339_5008">
                                        <rect width={16} height={16} fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <input placeholder={t('Search')} onChange={(e)=>setSearch(e.target.value)}/>
                        </div>
                        <div className={styles.select}>
                            <Select
                                isClearable
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[{ value: 1, label: 'Buy', color: '#00B4A0', background: 'rgba(0, 180, 160, 0.08)' }, { value: 2, label: 'Sell', color: '#F63D68', background: 'rgba(246, 61, 104, 0.08)' }]}
                                styles={colourStylesStatus(theme, 'Sale')}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={(selectedOption) => setSearchType(selectedOption)}
                                value={searchType}
                            />
                        </div>
                        {/* <div className={styles.select}>
                            <Select
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[{ value: 1, label: 'USD' }, { value: 2, label: 'EUR' }]}
                                styles={colourStylesCurrency(theme)}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={(selectedOption) => setSearchCurrency(selectedOption)}
                                value={searchCurrency}
                            />
                        </div> */}
                    </div>
                </div>
                <div className={styles.table}>
                    {
                        selectedTab === 'open' && (
                            <OrdersTable
                                data={currentRecordsB?.filter((val) => !val.Status.includes("D"))}
                                columns={columns}
                                theme={theme}
                            />
                        )
                    }
                    {
                        selectedTab === 'deferred' && (
                            <OrdersTable
                                data={currentRecordsB?.filter((val) => val.Status.includes("D"))}
                                columns={columnsDeferred}
                                theme={theme}
                            />
                        )
                    }
                    {
                        selectedTab === 'close' && (
                            <OrdersTable
                                data={historyB}
                                columns={columnsClosed}
                                theme={theme}
                            />
                        )
                    }
                </div>

                <div className={styles.mobile_transactions}>
                    {
                        selectedTab === 'close' && (
                            <div className={styles.transactions_list}>
                                {historyB.map(el =>
                                    <div onClick={toggleDrawer(true,el)}  className={styles.transaction} key={el.Id}>
                                        <div className={styles.type_status}>
                                            <div className={`${styles.left_side}`}>
                                            <img loading="lazy" src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.SubTitle.slice(1).slice(0,-1).toLowerCase()}.svg`} alt='icon'
                                                    onError={handleImgError}/>
                                                {el.SubTitle.slice(1).slice(0,-1).slice(0,4) }
                                                <span className={`${styles.type} ${el.OrderType?.includes('BUY') ? styles.buy : el.OrderType?.includes('SELL') ? styles.sell : styles.simulate}`}>{el.OrderType}</span>
                                                <span className={styles.leverage}>x{el.Leverage}</span>
                                            </div>
                                            <div className={styles.right_side}>
                                                <span className={`${styles.type} ${styles.simulate}`}>
                                                    {el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase()}
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className={styles.account_amount}>
                                            <div className={styles.account}>
                                                <label htmlFor="">{t('Profit')}</label>
                                                <p style={{color:parseFloat(el.Profit)> 0?'#00B4A0':"#F63D68"}}>
                                                    {el.Profit > 0? '+' : ''}
                                                    {parseFloat(`${el.Profit}`.slice(1)) > 1 ? ' ' + (el.Profit < 0 ? '- ' +  parseFloat(`${el.Profit}`.slice(1)).toFixed(2):el.Profit?.toFixed(2)) : ' ' + (el.Profit < 0 ? '- ' +  parseFloat(`${el.Profit}`.slice(1)).toFixed(5):el.Profit?.toFixed(5))}
                                                    {
                                                        account?.AccountCurrency == "EUR"
                                                            ?
                                                            " €"
                                                            :
                                                            " $"
                                                    }
                                                </p>
                                            </div>
                                            <div className={styles.account} style={{ alignItems: 'end' }}>
                                                <label htmlFor="">{t('Date')}</label>
                                                <p>
                                                    {moment(el.Date).format("YYYY-MM-DD HH:mm")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        )
                    }
                    {
                        selectedTab === 'deferred' && (
                            <div className={styles.transactions_list}>
                                {currentRecordsB?.filter((val) => val.Status.includes("D")).map(el =>
                                    <div onClick={toggleDrawer(true,el)} className={styles.transaction} key={el.Id}>
                                        <div className={styles.type_status}>
                                            <div className={`${styles.left_side}`}>
                                                <img loading="lazy" src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(1).slice(0,-1).toLowerCase()}.svg`} alt='icon'
                                                    onError={handleImgError}/>
                                                {el.Subtitle.slice(1).slice(0,-1).slice(0,4) }
                                            </div>
                                            <div className={styles.right_side}>
                                                <span className={styles.leverage}>x{el.Leverage}</span>
                                                <span className={`${styles.type} ${el.Status?.includes('BUY') ? styles.buy : el.Status?.includes('SELL') ? styles.sell : styles.simulate}`}>
                                                    {el.Status?.includes('D') ? (el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase()).slice(0, -1) : (el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase())}
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className={styles.account_amount}>
                                            <div className={styles.account}>
                                                <label htmlFor="">{t('TP/SL')}</label>
                                                <p className={styles.profit_loss}>
                                                    <span className={styles.take_profit}>
                                                        {
                                                            account?.AccountCurrency == "EUR"
                                                                ?
                                                                "€ "
                                                                :
                                                                "$ "
                                                        }
                                                        {el.TakeProfit ? parseFloat(el.TakeProfit) > 1 ? el.TakeProfit?.toFixed(2) : el.TakeProfit?.toFixed(5) : ' 0'}
                                                    </span>
                                                    /
                                                    <span className={styles.stop_loss}>
                                                        {
                                                            account?.AccountCurrency == "EUR"
                                                                ?
                                                                "€ "
                                                                :
                                                                "$ "
                                                        }
                                                        {el.StopLoss ? parseFloat(el.StopLoss) > 1 ? el.TakeProfit?.toFixed(2) : el.StopLoss?.toFixed(5) : ' 0'}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className={styles.account} style={{ alignItems: 'end' }}>
                                                <label htmlFor="">{t('Account')}</label>
                                                <p style={{alignItems:'end'}}>
                                                    {account?.Id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        )

                    }
                    {
                        selectedTab === 'open' && (
                            <div className={styles.transactions_list}>
                                {currentRecordsB?.filter((val) => !val.Status.includes("D")).map(el =>
                                    <div onClick={toggleDrawer(true,el)} className={styles.transaction} key={el.Id}>
                                        <div className={styles.type_status}>
                                            <div className={`${styles.left_side}`}>
                                                <img loading="lazy" src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(1).slice(0,-1).toLowerCase()}.svg`} alt='icon'
                                                    onError={handleImgError}/>
                                                {el.Subtitle.slice(1).slice(0,-1).slice(0,4) }
                                            </div>
                                            <div className={styles.right_side}>
                                                <span className={`${styles.type} ${el.Status?.includes('BUY') ? styles.buy : ''}`}>{el.Status}</span>
                                                <span className={styles.leverage}>x{el.Leverage}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className={styles.account_amount}>
                                            <div className={styles.account}>
                                                <label htmlFor="">{t('Amount')}</label>
                                                <p>
                                                    {
                                                        account?.AccountCurrency == "EUR"
                                                            ?
                                                            "€"
                                                            :
                                                            "$"
                                                    }{el.Price}</p>
                                            </div>
                                            <div className={styles.account} style={{ alignItems: 'end' }}>
                                                <label htmlFor="">{t('Account')}</label>
                                                <p style={{alignItems:'end'}}>
                                                    {account?.Id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <MobileOrderDrawer account={account} currentRecords = {currentRecords} theme={theme} open={openDrawer} eurToUsd={eurToUsd} toggleDrawer={toggleDrawer} activeOrder={activeOrder} marketPrice={activeOrder?.MarketPrice}/>
        </div>
    )
}